/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// react-router components
import { Routes, Route, Navigate, useLocation } from "react-router-dom";

// @mui material components
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
// Material Dashboard 2 React themes
import theme from "assets/theme";
// Material Dashboard 2 React routes
import routes from "routes";



import React, { useState, useEffect, useMemo } from 'react';
import { ConnectionProvider, WalletProvider } from '@solana/wallet-adapter-react';
import { WalletAdapterNetwork } from '@solana/wallet-adapter-base';
import {
    GlowWalletAdapter,
    PhantomWalletAdapter,
    SlopeWalletAdapter,
    SolflareWalletAdapter,
    
    TorusWalletAdapter,
} from '@solana/wallet-adapter-wallets';
import {
    WalletModalProvider,
} from '@solana/wallet-adapter-react-ui';
import { clusterApiUrl } from '@solana/web3.js';
import AOS from "aos";
import "aos/dist/aos.css";
import Logo from "./assets/big-logo.svg";


// Default styles that can be overridden by your app
require('@solana/wallet-adapter-react-ui/styles.css');
export default function Wallet() {

  
    const { pathname } = useLocation();
    const [loading, setLoading] = useState(false);
    // The network can be set to 'devnet', 'testnet', or 'mainnet-beta'.
    const network = WalletAdapterNetwork.Mainnet;

    // You can also provide a custom RPC endpoint.
    const endpoint = useMemo(() => clusterApiUrl(network), [network]);
    useEffect(() => {
      document.documentElement.scrollTop = 0;
      document.scrollingElement.scrollTop = 0;
      setTimeout(() => {
        setLoading(true);
      }, 3000);

    }, [pathname]);
  
    const getRoutes = (allRoutes) =>
      allRoutes.map((route) => {
        if (route.collapse) {
          return getRoutes(route.collapse);
        }
  
        if (route.route) {
          return <Route exact path={route.route} element={route.component} key={route.key} />;
        }
  
        return null;
    });
  
    // @solana/wallet-adapter-wallets includes all the adapters but supports tree shaking and lazy loading --
    // Only the wallets you configure here will be compiled into your application, and only the dependencies
    // of wallets that your users connect to will be loaded.
    const wallets = useMemo(
        () => [
            new PhantomWalletAdapter(),
            new GlowWalletAdapter(),
            new SlopeWalletAdapter(),
            new SolflareWalletAdapter({ network }),
            new TorusWalletAdapter(),
        ],
        [network]
    );

    AOS.init({
      once: true,
    });

    
    return (
    <>
        <ConnectionProvider endpoint={endpoint}>
            <WalletProvider wallets={wallets}  >
            
                <WalletModalProvider>
                    
                    <ThemeProvider theme={theme}>
      
                      <CssBaseline />
                      <Routes>
                        {getRoutes(routes)}
                        <Route path="*" element={<Navigate to="/" />} />
                      </Routes>
                    
                    
                  </ThemeProvider>
                </WalletModalProvider>
            </WalletProvider>
        </ConnectionProvider>
      
    
      </>
    );
};