import React, {
  useCallback,
  useEffect,
  useState
} from "react";

import Grid from "@mui/material/Grid";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import Stake from "./components/Stake";
import Container from "@mui/material/Container";
import HeaderLogo from "../../assets/img/png/headerlogo.png";
import logoimg from "../../assets/img/png/logo.png";
import { DiscordIcon, InstaIcon, TwitterIcon } from "./Icons";
import Footerimg from "../../assets/img/png/footerimg.png";
import purple from "../../assets/img/png/pink-bg-img.png";
import {isMobile} from 'react-device-detect';

// WalletProvider

// export const AppProvider = createContext();

import { LinearProgress} from "@material-ui/core";
import { WalletNotConnectedError } from "@solana/wallet-adapter-base";

import {
  useConnection,
  useWallet,
} from "@solana/wallet-adapter-react";
import { Keypair, SystemProgram, Transaction } from "@solana/web3.js";
import axios from "axios";
import UnStake from "./components/Stake/UnStake";
import { WalletMultiButton } from "@solana/wallet-adapter-react-ui";



const Coll_id = 21;

function BigFive() {
  const [collData, setCallData] = useState({});
  const [userData, setUserData] = useState([]);
  const [selectedStake, setSelectedStake] = useState([]);
  const [selectedStakeNfts, setSelectedStakeNfts] = useState([]);
  const [canClaim,setCanClaim] = useState();
  const {publicKey,  sendTransaction } = useWallet();
  //const publicKey ="3oTCX3CDeSFiMhn5VCZuDhmRBeg7f2fxrfC96NKHmaDW";
  const { connection } = useConnection();
  // const publicKey = "3oTCX3CDeSFiMhn5VCZuDhmRBeg7f2fxrfC96NKHmaDW";
  // const publicKey = "";

  // https://api.metastake.app/get_user?pub_key=&coll_id=13
  const getUser = useCallback(() => {
    // 
    //

    if (publicKey) {
      axios
        .get(
          `https://api.metastake.app/get_user?pub_key=${publicKey.toString()}&coll_id=${Coll_id}`
        )
        .then((res) => {
          setUserData(res.data);
          console.log(userData,"hereeeeeee");
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      setUserData([]);
    }
  }, [publicKey]);

  const getCallData = () => {
    axios
      .get("https://api.metastake.app/coll_data", {
        params: {
          coll_id: Coll_id,
        },
      })
      .then(({ data }) => {
        console.log(data, "bes res res");
        if (typeof data == "object") {
          setCallData(data);
        }
      })
      .catch((err) => {});
  };

  useEffect(() => {
    getCallData();
    if (!!publicKey) {
      getUser();
    }
  }, [getUser, publicKey]);

  const selecteStakeHendler = (stakeData) => {
    if (stakeData && stakeData.id) {
      let isExist = selectedStake.find(
        (stake, index) => stake.id == stakeData.id
      );
      console.log(isExist, "isExist");
      if (isExist && isExist.id) {
        let newSelectedStake = selectedStake.filter(
          (stake, index) => stake.id != stakeData.id
        );
        setSelectedStake(newSelectedStake);
      } else {
        setSelectedStake([...selectedStake, stakeData]);
      }
    }
  };

  const selecteStakeNftsHendler = (stakeData) => {
    if (stakeData && stakeData.id) {
      let isExist = selectedStakeNfts.find(
        (stake, index) => stake.id == stakeData.id
      );
      console.log(isExist, "isExist");
      if (isExist && isExist.id) {
        let newSelectedStake = selectedStakeNfts.filter(
          (stake, index) => stake.id != stakeData.id
        );
        setSelectedStakeNfts(newSelectedStake);
      } else {
        setSelectedStakeNfts([...selectedStakeNfts, stakeData]);
      }
    }
  };

  const saveStake = (mode = "") => {
    console.log(mode, "mode");
    let minsArr = [];
    if (mode === "All") {
      minsArr = userData.nft_to_stake.map((stake, index) => stake.mint);
    } else if (mode === "Selected") {
      minsArr = selectedStake.map((stake, index) => stake.mint);
    }
    console.log(minsArr, "minsArr");

    axios
      .post(
        `https://api.metastake.app/tx_stake?pub_key=${publicKey.toString()}&coll_id=${Coll_id}`,
        JSON.stringify(minsArr)
      )
      .then((res) => {
        console.log(res, "res res res");
        if (res && res.status === 200) {
          setUserData(res.data);
          console.log(res.data, "res.data");
          getCallData();
        }
      })
      .catch((err) => {
        console.log(err, "err err err");
      });
  };

  const saveStakeNfts = (mode = "") => {
    console.log(mode, "mode");
    let minsArr = [];
    if (mode === "All") {
      minsArr = userData.staked_nfts.map((stake, index) => stake.mint);
    } else if (mode === "Selected") {
      minsArr = selectedStakeNfts.map((stake, index) => stake.mint);
    }
    console.log(minsArr, "minsArr");

    axios
      .post(
        `https://api.metastake.app/unstake?pub_key=${publicKey.toString()}&coll_id=${Coll_id}`,
        JSON.stringify(minsArr)
      )
      .then((res) => {
        console.log(res, "res res res");
        if (res && res.status === 200) {
          setUserData(res.data);
          console.log(res.data, "res.data");
          getCallData();
        }
      })
      .catch((err) => {
        console.log(err, "err err err");
      });
  };

  const confStake = (transaction) => {
    axios
      .post(
        `https://api.metastake.app/conf_stake?pub_key=${publicKey.toString()}&coll_id=${Coll_id}&sig=${transaction}`
      )
      .then((res) => {
        console.log(res, "res res res");
      })
      .catch((err) => {
        console.log(err, "err err err");
      });
  };

  const claimGet = (e) => {
    axios
      .get(
        `https://api.metastake.app/claim?pub_key=${publicKey.toString()}&coll_id=${Coll_id}`
      )
      .then((res) => {
        console.log(res, "res res res");
        if (res && res.data && res.data.link) {
          window.open(res.data.link, "_blank");
        }
        if (res.data.value == -1 ){
          setCanClaim(res.data.message)
        }
      })
      .catch((err) => {
        console.log(err, "err err err");
      });
  };

  const onClick = useCallback(async () => {
    if (!publicKey) throw new WalletNotConnectedError();
    console.log(publicKey.toString(), "publicKey publicKey");

    const transaction = new Transaction().add(
      SystemProgram.transfer({
        fromPubkey: publicKey,
        toPubkey: Keypair.generate().publicKey,
        lamports: 1,
      })
    );

    const signature = await sendTransaction(transaction, connection);

    await connection.confirmTransaction(signature, "processed");
  }, [publicKey, sendTransaction, connection]);
  return (
   <>
    <section className="w-100 navbar-wrapper position-relative py-lg-3 ">
    <div className="container d-flex justify-content-between align-items-center ">
        <li className="mx-xxl-3 mx-1 nav-list-items p-2 d-inline-block active " >
            <a href="https://dimenxion.io/">Home</a>
        </li>
        <li  className="custom d-inline-block">
            <img className="nav-logo" src={HeaderLogo} alt="HeaderLogo" />
        </li>
        {isMobile ? <></>:<WalletMultiButton/>}
    </div>
    
</section>
<br/>
<br/>
<br/>

    <main 
      >

      <Container
        sx={{ px: 4 ,justifyItems:"center"}}
        className="mini-page container" 
        maxWidth="md"
      >
      <div className ="gradient-border mini-page-card">
        <Grid container spacing={2} sx={{ mb: 3 ,display: (isMobile?"grid":"raw"), justifyContent: "center"}} >
        {isMobile ?( 
          <Grid item xs={6} sm={6} md={6} lg={6} xl={6} sx={{ml:6}}>
            
            <WalletMultiButton/>
            <br/>
            <br/>
            
          </Grid>
          ):<></>}
          <Grid
            justifyContent="center"
            sx={{ pt: 0 }}
            className={`pt-0`}
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
          >
            
            <div className="text-center" >
              PHOTONS are particle of light which empower you to create, customize and interact inside Dimenxion MetaPlayGround. Gather light to enhance your experience !
            </div>
              <br/>
            <div className="text-center">
            <span style={{ color: "rgb(177 25 255 / 80%)" }}> Staked Portals : {"  "} </span>
            <span>{collData ? collData.staked_nfts : 0 } / {collData ? collData.total_nfts : 0}</span>
            </div>
              <LinearProgress
                className="my-lg-5 my-3 mx-xxl-5"
                variant="determinate"
                value={ (collData && collData.percentage ? collData.percentage : 0) }
              />
          </Grid>
        </Grid>
        
        <Grid container spacing={2} sx={{ mb: 3 }} >
          <Grid item xs={12} sm={6} md={6} lg={6} xl={6} >
              <Grid 
                container
                spacing={2}
                justifyContent="center"
                alignItems="flex-start"
              >
                <Grid
                className= " text-center"
                  item
                  xs={6}
                  sm={6}
                  md={6}
                  lg={6}
                  xl={6}

                >
                  
                  <span style={{ color: "rgb(177 25 255 / 80%)" }}>Balance : </span>
                    <span>
                    {" "}
                      {userData && userData.total_reward
                        ? userData.total_reward
                        : 0}{" "}
                      $PHOTONS
                    </span>
              
                </Grid>
              </Grid>
           
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={6} xl={6} >
        
              <Grid

               
                container
                spacing={2}
                justifyContent="center"
                alignItems="flex-start"
              >
                <Grid
                  item
                  xs={6}
                  sm={6}
                  md={6}
                  lg={6}
                  xl={6}
                  className="text-center"
                >
                 
                  <span style={{ color: "rgb(177 25 255 / 80%)" }}>Rate : </span>
                  <span>
                    {collData && typeof collData.reward_per_day != "undefined"
                      ? parseFloat(collData.reward_per_day)
                      : 0.0}{" "}
                    / day
                    </span>
                  
                </Grid>
              </Grid>
             
          </Grid>
        </Grid>
        
        {/* End hero unit */}
        <Grid
          container
          spacing={4}
          sx={{ mt: 2, display: "raw", justifyContent: "center" , mb: 3}}
        >
          <Grid item xs={12} sm={6} md={6}>
            
            
            <Stake
              userData={userData}
              selecteStakeHendler={selecteStakeHendler}
              selectedStake={selectedStake}
              public={publicKey}
            />
            {/* <Carousel
              autoPlay
              transitionTime={1000}
              interval={3000}
              infiniteLoop
              showArrows={false}
            >
              {userData.map((e, i) => {
                return (
                  <div>
                    <img
                      src={e?.image_url}
                      alt=""
                      style={{ width: "100%", borderRadius: "20px" }}
                    />
                  </div>
                );
              })}
            </Carousel> */}
          </Grid>

          <Grid item xs={12} sm={6} md={6}>
            
            <UnStake
              userData={userData}
              selecteStakeHendler={selecteStakeNftsHendler}
              selectedStake={selectedStakeNfts}
            />
            {/* <Carousel
              autoPlay
              transitionTime={1000}
              interval={3000}
              infiniteLoop
              showArrows={false}
            >
              {userData.map((e, i) => {
                return (
                  <div>
                    <img
                      src={e?.image_url}
                      alt=""
                      style={{ width: "100%", borderRadius: "20px" }}
                    />
                  </div>
                );
              })}
            </Carousel> */}
          </Grid>
        </Grid>
        
        <Grid container spacing={4} sx={{ mt: 2 ,gridAutoFlow:"row",display: (isMobile?"grid":"raw") ,justifyContent:"center", position:"relative"}}>
          <Grid item xs={6} sm={6} md={6} lg={6} xl={6} >
            
            <div className="d-lg-flex justify-content-center px-lg-5 px-sm-3 position-relative z-5">
              <div className="mt-3 mt-lg-0 mx-auto ">
              <button
              
                className="btn-bg bg-transparent border-0 fs-sm text-white"
                variant="contained"
                color="primary"
              
                //sx={{ px: 4, mr: 1 , ml:10}}
                onClick={() => {
                  saveStake("Selected");
                }}
                fullWidth={false}
                disabled={!publicKey}
              >
                Stake
              </button>
              </div></div>
          </Grid>
          <Grid item xs={6} sm={6} md={6} lg={6} xl={6} >
              <div className="d-lg-flex justify-content-center px-lg-5 px-sm-3 position-relative z-5">
              <div className="mt-3 mt-lg-0 mx-auto ">
              <button
                variant="contained"
                color="primary"
                //sx={{ px: 4, ml:10 }}
                onClick={() => {
                  saveStake("All");
                }}
                fullWidth={false}
                className="btn-bg bg-transparent border-0 fs-sm text-white"
                disabled={!publicKey}
              >
                STAKE ALL
              </button>
              </div></div>
          </Grid>
          <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
              <div className="d-lg-flex justify-content-center px-lg-5 px-sm-3 position-relative z-5">
                <div className="mt-3 mt-lg-0 mx-auto ">
              <button
                variant="contained"
                color="primary"
                //sx={{ px: 4,  ml: 10 }}
                onClick={() => {
                  saveStakeNfts("Selected");
                }}
                fullWidth={false}
                className="btn-bg bg-transparent border-0 fs-sm text-white"
                disabled={!publicKey}
              >
                UNSTAKE
              </button>
              </div></div>
          </Grid>
          <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
              <div className="d-lg-flex justify-content-center px-lg-5 px-sm-3 position-relative z-5">
              <div className="mt-3 mt-lg-0 mx-auto ">
              <button
                variant="contained"
                color="primary"
                //sx={{ px: 0, ml: 10 }}
                onClick={() => {
                  saveStakeNfts("All");
                }}
                fullWidth={false}
                className="btn-bg bg-transparent border-0 fs-sm text-white"
                disabled={!publicKey}
              >
                UNSTAKE ALL
              </button>
              </div></div>
            
          </Grid>
          <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
          <div className="d-lg-flex justify-content-center px-lg-5 px-sm-3 position-relative z-5">
            <div className="mt-3 mt-lg-0 mx-auto ">
              <button
                className="btn-bg bg-transparent border-0 fs-sm text-white"
                onClick={claimGet}
                disabled={!publicKey && !canClaim}
              >
                CLAIM
              </button>
              
            </div>
            
          </div>
          </Grid>
          
          
        </Grid>
        
        <br/>
        <br/>

        <p className="d-lg-flex justify-content-center px-lg-5 px-sm-3 position-relative z-5"> {canClaim?(<p>{canClaim}</p>):(<p></p>)}</p> 
      </div>
        
      </Container>
    </main>
    <section className="footer-section position-relative pt-5 pb-3">
      <div>
        <img className="d-block mx-auto" src={logoimg} alt="logoimg" />
      </div>
      <div className="text-center mt-4 position-relative z-5">
        <a href="https://twitter.com/Dimenxion_io">
          {" "}
          <TwitterIcon />
        </a>
        <a href="https://www.instagram.com/dimenxion.io/">
          <InstaIcon />
        </a>
        <a href="https://discord.gg/xvpPVNhxQA">
          <DiscordIcon />
        </a>
      </div>
      <div className="footer-border my-4"></div>
      <p className="copyright-text mb-0 pb-3 text-center">
        Copyright@Dimenxion.io.com
      </p>
      <img className="footer-img w-25" src={Footerimg} alt="Footerimg" />
      <img className="purple-img " src={purple} alt="shade-purple" />
    </section>
  </>
  );
}

export default BigFive;
